/* ABOUT TAB SECTION  */
.abtContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1400px;
  margin: 0 auto;
  padding: 5rem 0;
  color: var(--blue-color);
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tab {
  border: transparent;
  background: transparent;
  /* color: var(--blue-color); */
  opacity: 0;
  margin: 0.5rem 0;
  cursor: pointer;
}

.active {
  /* color: var(--dark-orange-color); */
  opacity: 0;
}

.abtContents {
  margin-inline: 6rem;
  padding: 0 15px;
}

.abtContents h2 {
  justify-items: flex-start;
  font-size: clamp(2rem, 9vw, 3rem);
  line-height: clamp(40px, 9vw, 55px);
}

.abtContents p {
  margin: 1rem 0;
  font-size: 1rem;
}

.learn_more {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
}

.downArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-orange-color);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0 1rem;
}

/* .bgImg{
    background: url('../../../assets/images/stroke.png') top right no-repeat;
} */

@media (max-width: 40em) {
  .abtContainer {
    flex-direction: column;
    padding: 0;
  }

  .buttonGroup {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
  }

  .tab {
    width: 100%;
    padding: 1rem 0;
    margin-top: 0;
    color: white;
    /* border-bottom: 1px solid #EBEBEB; */
  }

  .active {
    /* border-bottom: 1px solid var(--dark-orange-color); */
    /* background: #F5F6FA; */
  }

  .abtContents {
    margin-inline: 0;
  }

  .abtImg {
    padding: 0 15px;
    margin: 2rem 0;
  }

  .abtImg img {
    width: 100%;
  }
}

@media (min-width: 40em) and (max-width: 80em) {
  .abtContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }

  .buttonGroup {
    grid-column: 1/-1;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5rem;
  }

  .tab {
    width: 100%;
    padding: 1rem 0;
    margin-top: 0;
    border-bottom: 1px solid #ebebeb;
  }

  .active {
    border-bottom: 1px solid var(--dark-orange-color);
    background: #f5f6fa;
  }

  .abtContents h2 {
    justify-items: flex-start;
    font-size: 2rem;
    line-height: 40px;
  }

  .abtContents {
    margin-inline: 0;
    padding-bottom: 4rem;
  }

  .abtImg {
    padding: 0 15px;
    padding-bottom: 4rem;
  }

  .abtImg img {
    width: 100%;
  }
}
