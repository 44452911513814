/* FINANCIAL TAB SECTION  */
.plans {
  background: url("../../../assets/images/black-texture.png"),
    linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 15px;
}

.buttonGroup {
  display: flex;
  background: #2c3036;
  margin: 3rem auto;
  border-radius: 4px;
  padding: 0.25rem;
  width: max-content;
}

.tab {
  border: transparent;
  background: transparent;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  color: var(--white-color);
  white-space: nowrap;
}

.active {
  background: #dbc8a7;
  color: var(--blue-color);
  border-radius: 0.25rem;
}

.plansTab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem 0;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
}

.plansTab {
  -webkit-overflow-scrolling: touch;
}

.plansTab::-webkit-scrollbar {
  display: none;
}

.plansTab {
  overflow: -moz-scrollbars-none;
}

.plan {
  background: var(--light-pink);
  border-radius: 5px;
  color: var(--blue-color);
  padding: 68px 36px 39px;
  width: 397.4px;
  height: var(--ht);
  margin: 0 auto;
}

.plan:nth-child(2) {
  margin: 0 3.8rem;
}

.plan_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  background: var(--text-orange);
  border-radius: 50%;
  margin-bottom: 1.6rem;
}

.plan_icon img {
  width: 44px;
  height: 24px;
}

.plan_title {
  font-size: 32px;
}

.plan_desc {
  margin: 0.9rem 0px;
  line-height: 1.7rem;
  overflow-y: scroll;
  /* height: 150px; */
  overflow-y: auto;
}

.more {
  display: none;
}

@media (max-width: 40em) {
  .buttonGroup {
    width: 100%;
    max-width: max-content;
  }

  .plan {
    width: 250px;
  }
  .plan:nth-child(2) {
    margin: 0 2rem;
  }
}

@media (max-width: 80em) {
  .plan {
    flex: 0 0 auto;
  }

  .more {
    display: block;
    align-self: flex-start;
    color: rgba(255, 255, 255, 0.5);
  }
}
