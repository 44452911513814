
/* VARIABLES */
:root {
  /* COLORS */
  --white-color: #FFFF;
  --dark-orange-color: #C5A262;
  --light-gray: rgb(142, 121, 62, 0.05);
  --blue-color: #47536B;
  --light-pink: #FBF9ED;
  --black-color: rgba(0, 0, 0, 0);
  --text-orange: #DBC8A7;
  --light-pink: #FBF9ED;
  --dark-blue: #011934;

  /* FONT FAMILY */
  --hellix-light: "Hellix-Light", sans-seriff;
  --hellix-regular: "Hellix-Regular", sans-seriff;
  --hellix-medium: "Hellix-Medium", sans-seriff;
}

/* PRESETS */
*, *::before, *::after {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: var(--hellix-medium);
  line-height: 24px;
}

ul {
  list-style: none;
}

input,
button,
textarea,
select {
  font: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}