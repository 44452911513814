.terms {
  max-width: 1400px;
  margin: 0 auto;
  padding: 100px 15px;
  color: rgb(1, 27, 51);
}

.terms h2 {
  font-size: 2.8125rem;
  margin: 0 0 6.25px;
  line-height: normal;
}

.terms h3 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.75rem;
  color: #011b33;
  margin: 2.5rem 0 0.9375rem;
}

.terms p {
  font-size: 0.975rem;
  line-height: 1.5rem;
  font-weight: normal;
  margin: 6.25px 0;
}

.terms tr {
  border-radius: 1px solid black;
}

.terms td {
  font-size: 0.975rem;
  line-height: 1.5rem;
  width: 50%;
  border-radius: 1px solid black;
}
.terms table {
  border-collapse: collapse;
}

.terms td,
.terms th {
  border: 1px solid black; /* Add border to all sides of each cell */
}
.terms td:first-child {
  padding-left: 10px;
}
.terms thead td {
  font-weight: 600;
  padding: 10px;
}

.terms ul,
.terms ol {
  padding-left: 15px;
  margin-left: 15px;
  margin-bottom: 10px;
  list-style-type: circle;
}

.terms ul li {
  margin-bottom: 2px;
  font-size: 10px;
  line-height: 1.5rem;
}

.terms ol {
  list-style-type: decimal;
}

.terms a {
  color: #0ba4db;
  text-decoration: none;
  transition: color 0.3s ease;
}
