/* TESTIMONIALS  */
.testimonialWrapper {
    background: var(--dark-blue);
    color: #fff;

}

.testimonials {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem 15px;
  }
  
  .testimonies {
    display: flex;
    margin-top: 2rem;
    font-family: var(--hellix-light);
    flex-wrap: nowrap;
    padding: 2rem 0;
    overflow-x: auto;
    width: 100%;
  }

  .testimonies {
    -webkit-overflow-scrolling: touch;
  }
  
  .testimonies::-webkit-scrollbar {
      display: none;
  }
  
  .testimonies {
    overflow: -moz-scrollbars-none;
  }
  
  .testimony {
    flex: 0 0 auto;
    position: relative;
    background: rgba(71, 83, 107,0.1);
    border-radius: 10px;
    padding: 2rem;
    line-height: 28px;
    width: 250px;
    height: 320px;  
  }
  
  .testimony:not(:last-child) {
    margin: 0 2rem 0 0;
  }

  .text_center {
      align-self: center;
  }

  .desc {
    height: 250px;
    overflow-y: auto;
  }

  .user {
    display: flex;
    margin: 2rem 0;
    position: absolute;
    bottom: 0;
  }
  .userImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .userdetails {
    display: flex;
    flex-direction: column;
    margin:  0 2rem;
  }
  
  .userRole {
    font-family: var(--hellix-regular);
  }

  .more {
    display: none;
  }
  
  @media (max-width: 40em) {
    .testimony {
      width: 250px;
    }
  }
  
  @media (max-width: 80em) {
    /* .testimony {
      flex: 0 0 auto;
    } */
  
    .more {
      display: block;
      align-self: flex-start;
      color: rgba(255, 255, 255, 0.5);
    }
  }