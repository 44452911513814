footer {
    max-width: 1400px;
    margin: 0 auto;
    padding: 4rem 0.9rem;
    font-size: 0.95rem;
  }
  
  .footer_items {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
  }

  .footer_item {
      flex: .7;
  }

  .footer_item p {
    font-size: inherit;
  }
  
  .title{
     margin-bottom: 1.5rem;
     font-size: 1.25rem;
     white-space: nowrap;
  }
 
  .brandname{
    font-size: 2rem;
  }
 
  .footer_items ul li {
    margin: 1rem 0;
    cursor: pointer;
  }

  .footer_links {
      display: flex;
      flex-direction: column;
  }

  .footer_links > * {
      margin: 0 2.5rem;
  }
 
  .copyrights > *{
   margin: 1rem 0;
   font-size: 0.75rem;
   line-height: 1.25rem;
   color: var(--blue-color);
  }

  hr {
    margin: 1rem 0;
  }

  .social_icons {
    display: flex;
    align-items: center;
  }

  .social_icons > *:not(:first-child) {
    margin: 0 1rem;
  }

  .icon:hover{
    transition: .2s;
    fill: var(--dark-orange-color);
  }


  @media(max-width: 50em) { 
    footer {
      padding: 1rem 0.9rem;
    }

    .footer_items {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      gap: .5rem;
    }

    .footer_item {
      grid-column: 1/-1;
      margin-bottom: 2rem;
    }

    .footer_links:last-child {
      grid-column: 1/2;
      grid-row: 3/4;
    }

    .footer_links > * {
      margin: .5rem 0;
    }
  }

  @media (min-width: 50em) and (max-width: 80em) {
    .footer_items {
      display: grid;
      grid-template-columns: repeat(3,1fr);
      gap: 2rem;
    }

    .footer_links > * {
      margin: .5rem 0;
    }
  }