/* HERO SECTION */
.hero_container {
  padding-top: 88px;
  background: linear-gradient(
      to right,
      rgba(7, 19, 39, 0.95),
      rgba(7, 19, 39, 0.95)
    ),
    url("../../../assets/images/vectary-texture.png") no-repeat;
  background-size: cover;
}

.hero {
  max-width: 1400px;
  margin: 0 auto;
  padding: 100px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero_contents {
  flex: 1;
  color: var(--white-color);
  margin: 0 2rem;
}

.hero_title {
  text-transform: capitalize;
  font-weight: 600;
  font-size: clamp(2rem, 9vw, 4rem);
  line-height: clamp(50px, 9vw, 80px);
}

.hero_subtitle {
  margin: 1rem 10rem 0 0;
}

.hero_slider {
  flex: 1;
  display: flex;
  align-items: center;
}

.install_btns {
  display: flex;
  align-items: center;
  margin-top: clamp(1rem, 9vw, 3rem);
}

.install_btns svg {
  width: 100%;
}

.install_btns > a:last-child {
  margin-left: 1.2rem;
}

.hero_slider > img {
  width: 548.2px;
  height: 543.46px;
  border-radius: 10px;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0);
}

/* Mobile  */
@media (max-width: 40em) {
  .hero {
    flex-direction: column;
    padding: 50px 15px;
    align-items: flex-start;
  }

  .hero_contents {
    margin: 0;
  }

  .hero_subtitle {
    margin: 1rem 0;
  }

  .hero_slider {
    margin: 2rem 0;
  }

  .hero_slider > img {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 40em) and (max-width: 80em) {
  .hero_contents {
    margin: 0;
  }

  .hero_title {
    font-size: clamp(2rem, 5vw, 3rem);
    line-height: clamp(50px, 5vw, 65px);
  }

  .hero_subtitle {
    margin: 1rem 1rem 0 0;
  }

  .hero_slider > img {
    width: 100%;
    height: 100%;
  }

  .install_btns svg {
    width: 90%;
  }

  .install_btns > a:last-child {
    margin-left: 0.4rem;
  }
}
