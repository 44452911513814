.text_input {
  width: 423px;
  height: 50px;
  border-radius: 5px;
  background: rgb(71, 83, 107, 0.1);
  border: transparent;
  padding: 5px 20px;
}

.btn {
  background-color: var(--dark-orange-color);
  color: var(--white-color);
  padding: 0.7rem 1.6rem;
  opacity: 0.8;
  border-radius: 3px;
  border: transparent;
  cursor: pointer;
}
.btn:disabled {
  opacity: 0.3;
}

@media (max-width: 40em) {
  .text_input {
    max-width: 100%;
    padding: 5px 0;
  }

  .text_input::placeholder {
    padding: 0 0.4rem;
  }
}
