/* GET STARTED SECTION */
.container {
  max-width: 729px;
  margin: 1rem auto 0;
}

.get_started {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--light-gray);
  border-radius: 20px;
  padding: 3rem 15px;
}

.getStarted_title {
  font-size: 32px;
  margin: 1rem 0;
  text-align: center;
  line-height: clamp(34px, 9vw, 42px);
}

.input_container {
  position: relative;
  margin-top: 1rem;
}

.input_btn {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(0, -50%);
}

@media (max-width: 40em) {
  .container {
    margin: 0 auto 2rem;
  }

  .get_started {
    align-items: unset;
    border-radius: unset;
  }

  .input_container {
    display: flex;
    flex-direction: column;
  }

  .input_btn {
    position: relative;
    transform: unset;
    margin: 0.5rem 0;
    right: 0;
  }
}

@media (min-width: 40em) and (max-width: 80em) {
  .container {
    padding: 2rem 0.9rem;
  }
}
