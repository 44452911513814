/* .block {

} */

.text_orange{
  color: var(--dark-orange-color);
}

.sectionWrapper {
  max-width: 1400px;
  margin: 0 auto;
}

.heading {
  font-size: clamp(1.5rem, 5vw, 2.2rem);
  line-height: 44px;
  width: 456px;
  text-align: center;
}  

@media (max-width: 40em) { 
  .heading {
    width: unset;
  }
}