.process_container {
    display: flex;
    justify-content: space-between;
    padding: 7rem 0 5rem;
  }
  
  .img_display {
    flex: 1;
    background: url("../../../assets/images/circle-stroke.png") center no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img_display img {
    width: 448px;
    height: 892px;
    object-fit: cover;
  }
  
  .processes {
    flex: .7;
    padding: 0 2rem;
  }
  
  .heading {
    font-weight: 600;
    font-size: clamp(2rem, 9vw, 3rem);
    line-height: 55px;
    color: var(--blue-color);
  }
  
  .process_detail {
    display: flex;
    margin-top: 5rem;
    color: var(--blue-color);
    opacity: 0.6;
  }
  
  .number p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--dark-orange-color);
    color: var(--white-color);
  }
  
  .process_contents {
    margin: 0 1rem;
  }
  .process_contents h2 {
    margin: 0 0 1rem;
  }
  .process_contents p {
    width: 65%;
  }

  @media(max-width: 50em) { 
    .process_container {
      flex-direction: column-reverse;
      padding: 0 0 5rem;
    }

    .img_display img {
      width: 60%;
      height: 70%;
    }

    .processes {
      padding: 0 15px;
      margin: 5rem 0;
    }

    .process_contents p {
      width: 100%;
    }
  }

  @media(min-width: 50em) and (max-width: 80em) {

    .processes {
      padding: 0 15px;
      margin: 5rem 0;
    }

    .heading {
      font-size: 2.5rem;
    }

    .img_display {
      background-position: center;
    }

    .img_display img {
      width: 50%;
      object-fit: contain;
    }

    .processes {
      padding: 0 15px;
      margin: 5rem 0;
    }

    .process_contents p {
      width: 100%;
    }
   }