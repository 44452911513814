.header {
  padding: 1.3rem 0;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  background: var(--white-color);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.02);
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 0.9rem;
}
.nav > div:first-child {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 25.2746px;
  line-height: 32px;
  font-weight: 400;
  color: #47536c;
}
.nav > div:first-child svg {
  margin-right: 14px;
}
.nav_logo {
  width: 12rem;
  object-fit: cover;
}

.nav_menu {
  display: flex;
  align-items: center;
}

.nav_menu .nav_item {
  text-transform: uppercase;
  font-size: 0.875rem;
  cursor: pointer;
}

.nav_item:not(:last-child) {
  margin: 0 2rem;
}

.bg_orange {
  background-color: var(--dark-orange-color);
  color: var(--white-color);
  padding: 0.8rem 1.1rem;
  opacity: 0.8;
  border-radius: 3px;
  border: transparent;
  cursor: pointer;
}

.icon {
  display: none;
}

@media (max-width: 40em) {
  .nav_menu {
    position: absolute;
    width: 50%;
    height: 20vh;
    top: 85px;
    right: 0;
    z-index: 2;
    margin: auto;
    background-color: var(--white-color);
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  .active {
    transform: translateX(0%);
    transition: all 0.3s ease-in-out;
  }

  .icon {
    display: block;
    cursor: pointer;
  }

  .nav_menu .nav_item {
    margin: 1rem 0;
  }
}
